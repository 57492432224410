import React from 'react';

const StatusBar = ({ progressPercentage }) => {
  return (
    <div className="status-bar">
      <div className="status-bar-fill" style={{ width: `${progressPercentage}%` }}></div>
    </div>
  );
};

export default StatusBar;
