import logo from './OM-Logo.png';
import './App.css';
import React, { useState } from 'react';
import { Client } from '@hubspot/api-client';
import { FORM_STAGES, INQUIRY_OPTIONS, PROPERTY_OPTIONS, PROPERTY_USE_OPTIONS, BORROWER_OPTIONS, CITIZENSHIP_OPTIONS, EMPLOYMENT_OPTIONS } from './constants';
import StatusBar from './StatusBar';

const stageToKeyMap = {
  0: 'selectedInquiryType',
  1: 'selectedPropertyType',
  2: 'selectedPropertyUse',
  3: 'selectedBorrower',
  4: 'selectedCitizenship',
  5: 'selectedEmploymentType',
  6: 'selectedCountryOfResidence',
};

function App() {
 
  const [formData, setFormData] = useState({
    currentStage: 0,
    selectedInquiryType: '',
    selectedPropertyType: '',
    selectedPropertyUse: '',
    selectedBorrower: '',
    selectedCitizenship: '',
    selectedEmploymentType: '',
    selectedCountryOfResidence: '',
    phoneNumber: '',
    firstName: '',
    lastName: '',
    email: '',
  
  });
  const currentStageName = FORM_STAGES[formData.currentStage].name;
  const currentProgress = FORM_STAGES[formData.currentStage].progressValue;

  const Button = ({ className, onClick, children }) => (
    <button className={className} onClick={onClick}>
      {children}
    </button>
  );
  

  const backButtonClick = () => {
    setFormData(prevFormData => ({
      ...prevFormData,
      currentStage: prevFormData.currentStage - 1
    }));
  };
  
  const nextButtonClick = () => {
    setFormData(prevFormData => ({
      ...prevFormData,
      currentStage: prevFormData.currentStage + 1
    }));
  };
  



  function progressButtons({ currentStage }) {
    if (currentStage === 0) {
      return (
        <div className="App-navigation">
          <button className="App-next-button" onClick={nextButtonClick}>Next</button>
        </div>
      );
    } else if (currentStage >= 0 && currentStage <= 7) {
      return (
        <div className="App-navigation">
          <button className="App-back-button" onClick={backButtonClick}>Go Back</button>
          <button className="App-next-button" onClick={nextButtonClick}>Next</button>
        </div>
      );
    } else if (currentStage === 8) {
      return (
        <div className="App-navigation">
          <button className="App-back-button" onClick={backButtonClick}>Go Back</button>
          <button className="App-submit-button" onClick={submitButtonClick}>Submit</button>
        </div>
      );
    }
  }

  const submitButtonClick = () => {
    alert("Submit button clicked\n" + JSON.stringify(formData, null, 2));
  };

  const handleButtonClick = (stage, selection) => {
    setFormData(prevFormData => {
      const key = stageToKeyMap[stage];
      return {
        ...prevFormData,
        [key]: selection,
        currentStage: stage + 1
      };
    });
  };

  const OPTIONS_MAP = {
    0: INQUIRY_OPTIONS,
    1: PROPERTY_OPTIONS,
    2: PROPERTY_USE_OPTIONS,
    3: BORROWER_OPTIONS,
    4: CITIZENSHIP_OPTIONS,
    5: EMPLOYMENT_OPTIONS,
    // Add other stages options here if needed
  };


  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>Australian Mortgages for Expats & Overseas Residents</p>
        <StatusBar progressPercentage={currentProgress} />
        <p>{currentStageName}</p>
        <p>Version 3 July 2024</p>
      </header>

      <div className="App-form">
        {OPTIONS_MAP[formData.currentStage] && (
           <div className="App-form-section">
           {OPTIONS_MAP[formData.currentStage].map(option => (
             <Button
               key={option}
               className="App-option-button"
               onClick={() => handleButtonClick(formData.currentStage, option)}
             >
               {option}
             </Button>
           ))}
         </div>
       )}
     </div>



        {formData.currentStage === 6 && (
          <div className="App-form-section">
            <p>Country of Residence</p>
            <form className="App-entry">
              <input
                className="App-input"
                type="text"
                value={formData.selectedCountryOfResidence}
                onChange={(e) => setFormData(prevFormData => ({
                  ...prevFormData,
                  selectedCountryOfResidence: e.target.value
                }))}
                 
                placeholder="Enter your country of residence here"
              />
              <p>Phone number</p>
              <input
                className="App-input"
                type="tel"
                value={formData.phoneNumber}
                onChange={(e) => setFormData(prevFormData => ({
                  ...prevFormData,
                  phoneNumber: e.target.value
                }))}
                
                
                placeholder="Enter your contact number here"
              />
            </form>
          </div>
        )}

        {formData.currentStage === 7 && (
          <div className="App-form-section">
            <p>First Name</p>
            <form className="App-entry">
              <input
                className="App-input"
                type="text"
                value={formData.firstName}
                onChange={(e) => setFormData(prevFormData => ({
                  ...prevFormData,
                  firstName: e.target.value
                }))}
                
                
                placeholder="First Name"
              />
              <p>Last Name</p>
              <input
                className="App-input"
                type="text"
                value={formData.lastName}
                onChange={(e) => setFormData(prevFormData => ({
                  ...prevFormData,
                  lastName: e.target.value
                }))}
                
                
                placeholder="Last Name"
              />
            </form>
          </div>
        )}

        {formData.currentStage === 8 && (
          <div className="App-form">
            <div className="App-form-section">
              <p>Your Email</p>
              <form className="App-entry">
                <input
                  className="App-input"
                  type="text"
                  value={formData.email}
                  onChange={(e) => setFormData(prevFormData => ({
                    ...prevFormData,
                    email: e.target.value
                  }))}
                  
                  
                  placeholder="Enter your email here"
                />
                <p>Confirm Email</p>
                <input
                  className="App-input"
                  type="text"
                  value={formData.email}
                  onChange={(e) => setFormData(prevFormData => ({
                    ...prevFormData,
                    email: e.target.value
                  }))}
                  
                  
                  placeholder="Enter your email here"
                />
              </form>
              <div className="App-disclaimer">
                We are collecting your personal information in order to assist you with your home loan application or any other ancillary products.
                <p>By clicking the submit button you have read, consented and agree to be bound by our <a href="https://www.odinmortgage.com/privacy-security/">Privacy Policy</a> and agree to receiving emails from us.</p>
              </div>
            </div>
          </div>
        )}
        {progressButtons({ currentStage: formData.currentStage })} {/* Add this line to include the progressButtons function */}
      </div>
  );
}

export default App;
