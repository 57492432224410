export const FORM_STAGES = [
    { name: "Inquiry type", progressValue: 0 },
    { name: "Property Type", progressValue: 10 },
    { name: "Property Use", progressValue: 20 },
    { name: "Borrower", progressValue: 30 },
    { name: "Citizenship", progressValue: 40 },
    { name: "Employment Type", progressValue: 50 },
    { name: "Residence", progressValue: 65 },
    { name: "Your Name", progressValue: 80 },
    { name: "Email", progressValue: 90 },
  ];
  
  export const INQUIRY_OPTIONS = ['Pre-approval (Still searching)', 'Purchase (already found)', 'Refinance', 'Refinance and Cash-out'];
  export const PROPERTY_OPTIONS = ['Detached House', 'Apartment Unit', 'Land or Build'];
  export const PROPERTY_USE_OPTIONS = ['Investment Property', 'Owner Occupier', 'Holiday Home'];
  export const BORROWER_OPTIONS = ['Sole Borrower', 'Joint Borrower', 'Trust Entity', 'Non-trading Company'];
  export const CITIZENSHIP_OPTIONS = ['Australian Citizen', 'Australian PR Visa', 'Foreign National'];
  export const EMPLOYMENT_OPTIONS = ['Employee', 'Self Employed / Contractor', 'Unemployed / Home Duties'];
  

  